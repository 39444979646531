import { TBuilding, TEmployee } from '../../../../types/building'
import { RightPanel, SkidPagination, useSkidPagination } from '../../../common'
import {
  Box,
  Button, CardContent,
  CardHeader,
  LinearProgress,
  Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { useGetGroupsQuery, useGetWorksQuery } from '../../../../store/works'
import { useParams } from 'react-router-dom'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { formatDate } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import { useEffect, useMemo, useState } from 'react'
import { Item3View } from './Item3View'
import AddItem3Dialog from './AddItem3Dialog'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../../store/document'
import { documentType, IDocument, statusLabel, TDocumentStatus } from '../../../../types/document'
import { toastError } from '../../../../util/toastError'
import { useChecked } from '../../../../util/useChecked'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { toast } from 'react-toastify'
import { TFilterSetting, useFilter } from '../../../filter/useFilter'
import { worksList } from '../../../../util/works'
import { FilterPanel } from '../../../filter/FilterPanel'
import { FilterButton } from '../../../filter/FilterButton'
import { FilterChips } from '../../../filter/FilterChips'
import { locationString } from '../../../../util/location'
import { useGetStoreQuery } from '../../../../store/store'
import { CreateActMenu } from './CreateActMenu'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}


export const Gwj3ItemsList = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { pages, page, offset, limit, onPageChange, setTotal } = useSkidPagination(25)

  const { data, isLoading, isSuccess, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      type: documentType.GWJ3Item,
      parent_id: journalId,
    },
  })

  const {
    data: works,
    isLoading: isWorksLoading,
    isSuccess: isWorksSuccess,
  } = useGetWorksQuery(building.id)
  const { data: groups } = useGetGroupsQuery(building.id)

  const { data: employees } = useGetEmployeesQuery(building.id)

  const [removeItem] = useRemoveDocumentMutation()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.GWJ3Item', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.GWJ3Item', 'manage', currentItem.owner_id === user.id) &&
        ['draft', 'rework'].includes(currentItem.status) ) ||
      false
    )
  }, [currentItem, user])



  const statusItems = Object.keys(statusLabel)
    .map(k => ({label: statusLabel[k as TDocumentStatus], value: k}))
  const worksItems = useMemo(() => worksList(groups, works), [groups, works])
  const employeesItems = useMemo(
    () => {
      if (!data?.items || !employees) return []
      const list: Record<string, string> = data.items.reduce((acc, i) => ({
        ...acc,
        [i.employees.signer[0].employee_id]: signerName(employees, i)
      }), {}) || {}

      return Object.keys(list).map(id => ({value: id, label: list[id]}))

    }
    , [data, employees])

  const { data: store, refetch: refetchStore } = useGetStoreQuery(building.id)

  const isStore = useMemo(() => {
    if (!store) return {}
    const data: Record<string, boolean> = {}
    store.forEach((store) => {
      store.debits.forEach((debit) => {
        data[debit.item_id] = true
      })
    })
    return data
  }, [store])


  const filterSettings: TFilterSetting[] = [
    {name: 'works', label: 'Работа', filter: 'list', data: worksItems},
    {name: 'employees.signer', label: 'Ответственный', filter: 'list', data: employeesItems, prepare: (i => i.employee_id)},
    {name: 'date', label: 'Дата', filter: 'dateRange'},
    {name: 'status', label: 'Статус', filter: 'list', data: statusItems},
    {name: 'prd_documents', label: 'Без документов ПД/РД', filter: 'bool',
      handler: (item, value) => Object.keys(item.prd_documents).length === 0},
    {name: 'location', label: 'Без местоположения', filter: 'bool',
      handler: (item, value) => item.location === null},
    {name: 'id', label: 'Без материалов', filter: 'bool',
      handler: (item, value) => isStore && isStore[item.id] === undefined},
  ]

  const [filterOpen, setFilterOpen] = useState(false)
  const filter = useFilter(filterSettings)
  useEffect(() => {
    if (data?.items) {
      filter.setItems(data.items)
    }
  }, [data, filter])

  const filteredItems = useMemo(() => {
    return filter ? filter.filtered.slice(offset, offset + limit) : []
  }, [filter, offset, limit])

  const { onCheck, checked, isCheckedAll, isIndeterminate } = useChecked(
    filteredItems.map((i) => i.id)
  )

  useEffect(() => {
      setTotal(filter.filtered.length)
  }, [filter.filtered])


  if (isLoading || isWorksLoading || data === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const handleRemove = (id: string | undefined) => {
    if (id) {
      removeItem({
        buildingId: building.id,
        body: {
          type: documentType.GWJ3Item,
          document_id: id,
        },
      })
        .unwrap()
        .then(() => {
          setCurrentId(undefined)
          setDrawerOpen(false)
          toast.success('Запись удалена')
        })
        .catch(toastError)
    }
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <CardHeader
            title={'Сведения о выполнении работ'}
            action={
              <Stack direction={'row'} spacing={2}>
                <CreateActMenu buildingId={building.id} items={checked} />
                <FilterButton count={filter.state.count} onClick={() => setFilterOpen(true)} />
                {isCreate && <AddItem3Dialog buildingId={building.id} />}
              </Stack>
            }
          />
          <CardContent>
            <FilterChips filter={filter} />
          </CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '50px' }}>
                  <Checkbox
                    checked={isCheckedAll}
                    value={'all'}
                    onChange={onCheck}
                    indeterminate={isIndeterminate}
                  />
                </TableCell>
                <TableCell>Работа, место проведения</TableCell>
                <TableCell>Ответственный</TableCell>
                <TableCell sx={{textAlign: 'right'}}>Дата</TableCell>
                <TableCell>Статус документа</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item) => (
                <TableRow key={item.id} hover sx={{ cursor: 'pointer' }}>
                  <TableCell>
                    <Checkbox
                      checked={checked.includes(item.id)}
                      value={item.id}
                      onChange={onCheck}
                    />
                  </TableCell>
                  <TableCell onClick={() => viewItem(item.id)}>
                    <Box>
                      <Typography variant={'body1'}>
                        {works && works[item.works[0]].name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant={'caption'} color={'grey'}>
                        {item.location && locationString(item.location)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => viewItem(item.id)}>
                    {employees && signerName(employees, item)}
                  </TableCell>
                  <TableCell sx={{textAlign: 'right'}} onClick={() => viewItem(item.id)}>
                    {formatDate(item.date)}
                  </TableCell>
                  <TableCell onClick={() => viewItem(item.id)}>
                    <StatusChip status={item.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {data.items && data.items.length > 0 && (
            <SkidPagination page={page} pages={pages} onChange={onPageChange} />
          )}
        </TableContainer>
      </Paper>
      {currentItem && (
        <RightPanel
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
          actions={
            <>
              {isManage && (
                <Button
                  variant={'outlined'}
                  color={'error'}
                  onClick={() => handleRemove(currentItem?.id)}
                >
                  Удалить
                </Button>
              )}
            </>
          }
        >
          <Item3View
            building={building}
            item={currentItem}
            onReady={refetch}
            onSign={refetch}
            onChange={refetch}
            isManage={isManage}
          />
        </RightPanel>
      )}
      <FilterPanel
        open={filterOpen}
        onOpen={() => setFilterOpen(true)}
        onClose={() => setFilterOpen(false)}
        filter={filter}
      />
    </>
  )
}
