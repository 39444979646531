import 'dayjs/locale/ru'
import dayjs from 'dayjs'

dayjs.locale('ru')

export function formatDate(date: string): string {
  const tDate = dayjs(date)
  if (tDate.isValid()) {
    return tDate.format('LL')
  }
  return ''
}
export function formatDateTime(date: string, alt: string|null = null): string {
  const tDate = dayjs(date)
  if (tDate.isValid()) {
    return tDate.format('LLL')
  }
  return alt !== null ? alt : ''
}

export function formatPeriod(from: string, to: string): string {
  const fromDate = dayjs(from)
  const toDate = dayjs(to)

  if (!fromDate.isValid() || !toDate.isValid()) return ''

  const fd = fromDate.format('DD')
  const td = toDate.format('DD')

  const fm = fromDate.format('MMM')
  const tm = toDate.format('MMM')

  const fy = fromDate.format('YYYY')
  const ty = toDate.format('YYYY')

  if (fy === ty) {
    if (fm === tm) {
      return `${fd} - ${td} ${fm} ${fy}`
    }
    return `${fd} ${fm} - ${td} ${tm} ${fy}`
  }
  return `${fd} ${fm} ${fy} - ${td} ${tm} ${ty}`
}

export function formatPeriodDig(from: string, to: string): string {
  const fromDate = dayjs(from)
  const toDate = dayjs(to)

  const fd = fromDate.format('L')
  const td = toDate.format('L')
  const ft = fromDate.format('HH:mm')
  const tt = toDate.format('HH:mm')

  if (fd === td) {
    return `${fd} (${ft} — ${tt})`
  }
  return `${fd} ${ft} — ${td} ${tt}`
}
