import { IDocument } from '../../types/document'
import { useBuildMutation } from '../../store/document'
import { LightLoadingButton } from '../common/LightButton'
import React, { useMemo, useState } from 'react'
import { toastError } from '../../util/toastError'
import { useAuthorization } from '../../auth'
import { useGetUserQuery } from '../../store/user'
import { useConfirm } from '../../util/useConfirm'

type Props = {
  document: IDocument
  size?: 'small' | 'medium'
  onBuild?: () => void
  text?: string
  isConfirm?: boolean
}

export const BuildButton = ({document, size = 'medium', text = 'Сформировать', onBuild, isConfirm = false}: Props) => {

  const [build] = useBuildMutation()
  const [isLoading, setIsLoading] = useState(false)
  const confirm = useConfirm()

  const { data: user} = useGetUserQuery()
  const authManager = useAuthorization()
  const isOwner = useMemo(() => {
    return user ? authManager.vote(`Document.${document.type}`, 'manage', user.id === document.owner_id) : false
  }, [document, user, authManager])

  const isAllowed = ['rework', 'draft'].includes(document.status) && isOwner

  const send = () => {
    setIsLoading(true)
    build({ buildingId: document.building_id, body: { document_id: document.id } })
      .unwrap()
      .then(() => onBuild && onBuild())
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  const handleBuild = () => {
    if (isConfirm) {
      confirm({
        title: text,
        content: 'Документ будет сформирован, и перейдет в статус подписания/согласования. Продолжить?'
      }).then(() => {
        send()
      })
    } else {
      send()
    }
  }

  return(
    <span>
      {isAllowed && <LightLoadingButton color={'primary'} onClick={handleBuild} size={size} loading={isLoading}>
        <span>{text}</span>
      </LightLoadingButton>}
    </span>
  )
}
