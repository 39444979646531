import { IDocument } from '../../../../types/document'
import { TBuilding, TEmployee } from '../../../../types/building'
import { Card, CardContent, CardHeader } from '@mui/material'
import { formatDate } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import * as React from 'react'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { SignDocument } from '../../../document/SignDocument'
import Box from '@mui/material/Box'
import { DataRow } from '../../../common/DataRow'
import { EditRecord4Dialog } from './EditRecord4Dialog'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { useMemo } from 'react'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    (item.employees &&
      employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
        .full_name) ||
    ''
  )
}

type Props = {
  building: TBuilding
  item: IDocument
  onChange: () => void
  onReady: () => void
  onSign: () => void
  onDefectReady?: () => void
  onDefectSign?: () => void
}

export const Record4View = ({
  building,
  item,
  onChange,
  onReady,
  onSign,
  onDefectReady,
  onDefectSign,
}: Props) => {
  const { data: employees } = useGetEmployeesQuery(building.id)

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isManage = useMemo(() => {
    if (!['draft', 'rework'].includes(item.status)) return false
    return (
      (user && authManager.vote('Document.GWJ4Item', 'manage', item.owner_id === user.id)) || false
    )
  }, [item, user])

  return (
    <>
      <Card>
        <CardHeader
          title={'Сведения об отсутствии недостатков'}
          subheader={' '}
          action={<StatusChip status={item.status} />}
        />
        <CardContent>
          <DataRow label={'Дата внесения записи'} text={formatDate(item.date)} />
          <DataRow label={'Описание'} text={item.data.description} />
          <DataRow
            label={'Сотрудник, проводивший контроль устранения недостатка'}
            text={employees && signerName(employees, item)}
          />
        </CardContent>
        {isManage && (
          <CardContent>
            <EditRecord4Dialog item={item} />
          </CardContent>
        )}
      </Card>

      <Box sx={{ mt: 2 }}>
        <SignDocument document={item} onReady={onReady} onChange={onSign} />
      </Box>
    </>
  )
}
