import { IDocument } from '../../types/document'
import { useAddAgreementMutation } from '../../store/document'
import { useGetUserQuery } from '../../store/user'
import React, { useId, useMemo, useState } from 'react'
import { toastError } from '../../util/toastError'
import { LoadingButton } from '@mui/lab'
import {
  Box, Button, Dialog, DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAuthorization } from '../../auth'
import { LightButton } from '../common/LightButton'
import { useGetEmployeesQuery } from '../../store/employees'
import { useGetCompaniesQuery } from '../../store/companies'
import { employeesList } from '../../util/employees'
import FormSelect from '../form/FormSelect'

const DEFAULT_TEXT = 'Добавить согласующего'

const registerSchema = object({
  employee_id: string().min(1),
})

const renderDefaultButton = (onClick: () => void, text?: string, size?: 'small' | 'medium') => {
  return <LightButton onClick={onClick} size={size || 'medium'}>{text || DEFAULT_TEXT}</LightButton>
}


type Props = {
  document: IDocument
  onAdd?: () => void
  size?: 'small' | 'medium'
  text?: string
  renderButton?: (onClick: () => void, text?: string, size?: 'small' | 'medium') => React.ReactElement
}
const AddAgreementButton = ({ document, onAdd, size = 'medium', text = DEFAULT_TEXT, renderButton }: Props) => {

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const id = useId()
  const { data: employees } = useGetEmployeesQuery(document.building_id)
  const { data: companies } = useGetCompaniesQuery(document.building_id)

  const [add] = useAddAgreementMutation()

  const { data: user} = useGetUserQuery()
  const authManager = useAuthorization()

  const employeeItems = useMemo(() => {
    if (!companies || !employees) return []
    const exclude = document.agreements.map(a => a.employee_id)
    return employeesList(companies, employees, null, null, exclude)
  }, [companies, employees])


  const isOwner = useMemo(() => {
    return user ? authManager.vote(`Document.${document.type}`, 'manage', user.id === document.owner_id) : false
  }, [document, user, authManager])

  const signer = useMemo(() => {
    if (!user || !document.agreements) return undefined
    return document.agreements.find((ag) => ag.user_id === user.id)
  }, [user])

  const isAllowed = (isOwner || signer) && employeeItems.length > 0 && ['sign'].includes(document.status)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema)
  })

  const { handleSubmit } = methods


  const handleClickOpen = () => {
    setOpen(true)
  }

  const submitHandler = (values: any) => {
    setIsLoading(true)
    add({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
        ...values
      },
    })
      .unwrap()
      .then((res) => {
        onAdd && onAdd()
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  if (!isAllowed) return null

  return (
    <>

      {renderButton
        ? renderButton(handleClickOpen, text, size)
        : renderDefaultButton(handleClickOpen, text, size)
      }

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <FormProvider {...methods}>
          <Box
            id={id}
            component="form"
            onSubmit={handleSubmit(submitHandler)}
            noValidate
            autoComplete="off"
          >
            <DialogTitle>{'Добавить согласующего'}</DialogTitle>
            <DialogContent>
              <FormSelect
                name="employee_id"
                label="Сотрудник"
                items={employeeItems}
              />
            </DialogContent>
            <DialogActions>
              <LoadingButton type={'submit'} variant={'outlined'} color={'success'} loading={isLoading}>
                Отправить
              </LoadingButton>
              <Button onClick={() => setOpen(false)} autoFocus>
                Закрыть
              </Button>
            </DialogActions>
          </Box>
        </FormProvider>
      </Dialog>
    </>
  )
}

export { AddAgreementButton }
