import { IDocument } from '../../types/document'
import React from 'react'
import {
  Box, IconButton, Link,
  List,
  ListItem, ListItemButton,
  ListItemIcon, ListItemText, Popover, Typography
} from '@mui/material'
import { TEmployee } from '../../types/building'
import { useGetEmployeesQuery } from '../../store/employees'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { formatDateTime } from '../../util/date'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import { useRemoveAgreementMutation } from '../../store/document'
import { toastError } from '../../util/toastError'
import { AddAgreementButton } from './AddAgreementButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const signerName = (employees: TEmployee[], employee_id: string): string => {
  return employees.find((em) => em.id === employee_id)?.profile.name.full_name || ''
}

const popupComment = (comment: string|null) => {
  if (comment === null) return 'отказ'
  return(
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Box>
            {'отказал'} <Link sx={{cursor: 'pointer'}} {...bindTrigger(popupState)}>{'коментарий'}</Link>
          </Box>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography sx={{ p: 2 }}>{comment}</Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

type Props = {
  document: IDocument
  onChange?: () => void
}
export const SignDocumentList = ({document, onChange}: Props) => {

  const { data: employees } = useGetEmployeesQuery(document.building_id)
  const [removeAgreement] = useRemoveAgreementMutation()


  const handleRemoveAgreement = (id: string) => {
    removeAgreement({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
        agreement_id: id
      }
    }).unwrap()
      .catch(toastError)

  }

  return(
    <List dense>
      {document.agreements && document.agreements.filter(s => s.is_complete).map((agreement) => (
        <ListItem
          disableGutters
          key={agreement.id}
        >
          <ListItemIcon sx={{width: 50, minWidth: 50}}>
            {agreement.is_agree && <CheckCircleOutlineIcon color={agreement.is_signer ? 'success' : 'info'} />}
            {!agreement.is_agree && <HighlightOffIcon color={'error'} />}
          </ListItemIcon>
          <ListItemText
            sx={{flexGrow: 1}}
            primary={employees && signerName(employees, agreement.employee_id)}
            secondary={agreement.is_agree ? formatDateTime(agreement.signed_time) : popupComment(agreement.comment)}
            secondaryTypographyProps={{color: 'text.disabled'}}
          />
        </ListItem>
      ))}
      {document.agreements && document.agreements.filter(s => !s.is_complete).map((agreement) => (
        <ListItem
          disableGutters
          key={agreement.id}
          secondaryAction={!agreement.is_signer &&
            <IconButton edge="end" aria-label="delete" color={'error'} onClick={() => handleRemoveAgreement(agreement.id)}>
              <DeleteIcon />
            </IconButton>
          }
        >
          <ListItemIcon sx={{width: 50, minWidth: 50}}>
            {document.status === 'rework' ?  <ClearIcon color={'disabled'} /> : <AccessTimeIcon color={'disabled'} />}
          </ListItemIcon>
          <ListItemText
            sx={{flexGrow: 1}}
            primary={employees && signerName(employees, agreement.employee_id)}
            secondary={document.status === 'rework' ? 'не учавствовал' : 'ожидание'}
            primaryTypographyProps={{color: 'action.disabled'}}
            secondaryTypographyProps={{color: 'action.disabled'}}
          />
        </ListItem>
      ))}
      <AddAgreementButton
        document={document} onAdd={onChange}
        renderButton={(onClick, text, size) => (
          <ListItem disableGutters>
            <ListItemIcon sx={{width: 50, minWidth: 50}}>
              <AddCircleOutlineIcon color={'info'} />
            </ListItemIcon>
            <ListItemText
              primary={<Link onClick={onClick} sx={{cursor: 'pointer'}}>{text}</Link>}
              sx={{flexGrow: 1}}
            />
          </ListItem>
        )}
      />
    </List>
  )
}
