import { IDocument } from '../../types/document'
import { LightLoadingButton } from '../common/LightButton'
import React, { useMemo, useState } from 'react'
import { useReworkMutation } from '../../store/document'
import { toastError } from '../../util/toastError'
import { useConfirm } from '../../util/useConfirm'
import { useGetUserQuery } from '../../store/user'
import { useAuthorization } from '../../auth'

type Props = {
  document: IDocument
  onRework?: () => void
  text?: string
  size?: 'small' | 'medium'
  renderButton?: (onClick: () => void) => React.ReactElement
}

export const ReworkButton = ({document, size = 'medium', text = 'На доработку', onRework, renderButton}: Props) => {

  const [rework] = useReworkMutation()
  const [isLoading, setIsLoading] = useState(false)
  const confirm  = useConfirm()


  const { data: user} = useGetUserQuery()
  const authManager = useAuthorization()
  const isOwner = useMemo(() => {
    return user ? authManager.vote(`Document.${document.type}`, 'manage', user.id === document.owner_id) : false
  }, [document, user, authManager])

  const isAllowed = ['sign'].includes(document.status) && isOwner

  const handleRework = () => {

    confirm({
      title: 'Отправить документ на доработку?',
      content: 'Документ перейдет в статус На доработке и станет доступен для редактирования. Согласование будет завершено досрочно.'
    }).then(() => {

      setIsLoading(true)

      rework({
        buildingId: document.building_id,
        body: {
          document_id: document.id,
        },
      })
        .unwrap()
        .then((res) => {
          onRework && onRework()
        })
        .catch(toastError)
        .finally(() => setIsLoading(false))

    })
  }

  if (!isAllowed) return null

  return (
    <>
      {renderButton && renderButton(handleRework)}
      {!renderButton &&
        <LightLoadingButton onClick={handleRework} size={size} loading={isLoading}>
          <span>{text}</span>
        </LightLoadingButton>
      }
    </>
  )
}
