import { IDocument } from '../../../../types/document'
import { Alert, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material'
import { StatusChip } from '../../../common/StatusChip'
import * as React from 'react'
import { DataRow } from '../../../common/DataRow'
import { formatDate } from '../../../../util/date'
import { TBuilding, TEmployee } from '../../../../types/building'
import { useGetEmployeesQuery } from '../../../../store/employees'
import ExtDocLine from '../../../ExternalDocuments/ExtDocLine'
import { useGetExternalDocumentsQuery } from '../../../../store/externalDocuments'
import { useMemo } from 'react'
import { Part7DefectDialog } from './Part7DefectDialog'
import { Part7ItemDialog } from './Part7ItemDialog'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { SignDocument } from '../../../document/SignDocument'
import Box from '@mui/material/Box'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}

type Props = {
  building: TBuilding
  document: IDocument
  defect: IDocument
  onChange: () => void
  onReady: () => void
  onSign: () => void
}
const Part7View = ({ building, document, defect, onChange, onReady, onSign }: Props) => {
  const { data: employees } = useGetEmployeesQuery(document.building_id)
  const { data: extDocs } = useGetExternalDocumentsQuery({ buildingId: document.building_id })

  const actKnm = useMemo(() => {
    if (!extDocs) return undefined
    return extDocs.items.find(
      (d) => defect.external_documents.actKnm[0].external_document_id === d.id
    )
  }, [extDocs, defect])

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isManage = useMemo(() => {
    return (
      (user && authManager.vote('Document.GWJ7Item', 'manage', document.owner_id === user.id)) ||
      false
    )
  }, [document, user])

  return (
    <Grid container spacing={2} alignItems={'stretch'}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title={'Сведения о ГСН'} action={<StatusChip status={defect.status} />} />
          <CardContent>
            <DataRow
              label={'Учетный номер проверки'}
              text={defect.data.controlEventRegistryNumber}
            />
            <DataRow
              label={'Данные о результатах КНМ'}
              text={defect.data.controlEventResultsInfo}
            />
            {actKnm && (
              <DataRow label={'Акт КНМ'}>
                <ExtDocLine document={actKnm} />
              </DataRow>
            )}
            {defect.data.defectsFixingPlanDateInfo && (
              <DataRow
                label={'Дата, к наступлению которой выявленные нарушения должны быть устранены'}
                text={formatDate(defect.data.defectsFixingPlanDateInfo)}
              />
            )}
            <DataRow label={'Должностное лицо'} text={employees && signerName(employees, defect)} />
          </CardContent>
          {isManage && defect.status === 'draft' && (
            <CardContent>
              <Part7DefectDialog defect={defect} buildingId={defect.building_id} />
            </CardContent>
          )}
        </Card>
        <Box sx={{ mt: 2 }}>
          <SignDocument
            document={defect}
            onReady={onReady}
            onChange={onSign}
            description={'подписание данных о выявленном недостатке'}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title={'Устранение'} action={<StatusChip status={document.status} />} />
          <CardContent>
            <DataRow
              label={'Фактическая дата устранения выявленных нарушений'}
              text={
                document.data && document.data.defectsFixingFactDateInfo
                  ? formatDate(document.data.defectsFixingFactDateInfo)
                  : 'не задано'
              }
            />
            <DataRow
              label={'Причина снятия нарушения с контроля'}
              text={(document.data && document.data.defectControlDenialReason) || 'не задано'}
            />
            <DataRow
              label={'Должностное лицо'}
              text={employees && signerName(employees, document)}
            />
          </CardContent>
          {isManage && document.status === 'draft' && (
            <CardContent>
              <Part7ItemDialog item={document} />
            </CardContent>
          )}
        </Card>
        <Box sx={{ mt: 2 }}>
          {defect.status === 'success' ? (
            <SignDocument
              document={document}
              onReady={onReady}
              onChange={onSign}
              description={'подписание данных об устранении недостатка'}
            />
          ) : (
            <Card>
              <CardContent>
                <Alert severity={'info'}>
                  Подписание данных об устранении недостатка, будет доступно после подписания данных
                  о выявленном недостатке
                </Alert>
              </CardContent>
            </Card>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export { Part7View }
