import { IDocument } from '../../types/document'
import { useDenyMutation } from '../../store/document'
import { useGetUserQuery } from '../../store/user'
import { useGetProfileQuery } from '../../store/profile'
import React, { useId, useMemo, useState } from 'react'
import { toastError } from '../../util/toastError'
import { LoadingButton } from '@mui/lab'
import {
  Box, Button, Dialog, DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormInput from '../form/FormInput'


const registerSchema = object({
  comment: string().min(1),
})


type Props = {
  document: IDocument
  onDeny?: () => void
  size?: 'small' | 'medium'
}
const DenyButton = ({ document, onDeny, size = 'medium' }: Props) => {

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const id = useId()

  const { data: user } = useGetUserQuery()
  const [deny] = useDenyMutation()

  const signer = useMemo(() => {
    if (!user || !document.agreements) return undefined
    return document.agreements.find((ag) => ag.user_id === user.id && !ag.is_complete)
  }, [user])

  const isAllowed = signer && ['sign'].includes(document.status)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema)
  })

  const { handleSubmit } = methods


  const handleClickOpen = () => {
    setOpen(true)
  }

  const submitHandler = (values: any) => {
    setIsLoading(true)
    deny({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
        ...values
      },
    })
      .unwrap()
      .then((res) => {
        onDeny && onDeny()
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  if (!isAllowed) return null

  return (
    <>
      <LoadingButton variant={'outlined'} color={'primary'} onClick={handleClickOpen} size={size}>
        Отказать
      </LoadingButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <FormProvider {...methods}>
          <Box
            id={id}
            component="form"
            onSubmit={handleSubmit(submitHandler)}
            noValidate
            autoComplete="off"
          >
            <DialogTitle>{'Отказать'}</DialogTitle>
            <DialogContent>
              <FormInput name="comment" label="Комментарий, причина отказа" />
            </DialogContent>
            <DialogActions>
              <LoadingButton type={'submit'} variant={'outlined'} color={'success'} loading={isLoading}>
                Отправить
              </LoadingButton>
              <Button onClick={() => setOpen(false)} autoFocus>
                Закрыть
              </Button>
            </DialogActions>
          </Box>
        </FormProvider>
      </Dialog>
    </>
  )
}

export { DenyButton }
