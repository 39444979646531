import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { IDocument } from '../../types/document'
import { xmlSignature } from '../../util/signature'
import { toastError } from '../../util/toastError'
import { useApi } from '../../api/useApi'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle, Button, Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel
} from '@mui/material'
import { CertList } from '../sign/CertList'
import { Link } from 'react-router-dom'
import { useGetProfileQuery } from '../../store/profile'

type Props = {
  document: IDocument
  open: boolean
  setOpen: (open: boolean) => void
  onSign: (signature: any) => void
}

export const SignDialog = ({ document, open, setOpen, onSign }: Props) => {

  const [isLoading, setIsLoading] = useState(false)
  const [cert, setCert] = useState<string>('')
  const { data: profile } = useGetProfileQuery()

  const api = useApi()

  const certs = useMemo(() => {
      if (!profile) return undefined
      return profile.certificates.filter((cert) => new Date() < new Date(cert.period_to))
    },
    [profile]
  )

  const handleSign = async () => {
    if (!cert) {
      toast.error('Выберите сертификат')
      return
    }

    setIsLoading(true)

    api.get(`/v1/buildings/${document.building_id}/document/xml?document_id=${document.id}`).then(xml => {
      return xmlSignature(xml, `_${document.id}`, cert)
    }).then((signature) => {
      onSign(signature)
    }).catch(toastError)
      .finally(() => setIsLoading(false))

  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>{'Подписание'}</DialogTitle>
      {certs && certs.length > 0 && (
        <DialogContent>
          <DialogContentText>Выберите сертификат для подписания</DialogContentText>
          <FormControl sx={{ mt: 2, width: '100%' }}>
            <InputLabel>Сертификат</InputLabel>
            {profile && <CertList profile={profile} onChange={(v) => setCert(v)} />}
          </FormControl>
        </DialogContent>
      )}
      {certs && certs.length === 0 && (
        <DialogContent>
          <Alert severity={'warning'}>
            <AlertTitle>Отсутствуют сертификаты</AlertTitle>
            Для подписания добавьте сертификат в <Link to={'/profile'}>профиле</Link>.
            <br />
            Если вы уже добавляли сертификат, возможно истек срок действия.
          </Alert>
        </DialogContent>
      )}
      <DialogActions>
        {cert.length > 0 && <LoadingButton onClick={handleSign} variant={'outlined'} color={'success'} loading={isLoading}>
            Подписать
          </LoadingButton>}
        <Button onClick={() => setOpen(false)} autoFocus>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}
