import { IDocument } from '../../types/document'
import { useSignMutation } from '../../store/document'
import { SignDialog } from './SignDialog'
import { useGetUserQuery } from '../../store/user'
import React, { useMemo, useState } from 'react'
import { toastError } from '../../util/toastError'
import { LoadingButton } from '@mui/lab'


type Props = {
  document: IDocument
  onSign?: () => void
  size?: 'small' | 'medium'
}
const SignButton = ({ document, onSign, size = 'medium' }: Props) => {

  const [open, setOpen] = useState(false)

  const { data: user } = useGetUserQuery()
  const [sign] = useSignMutation()

  const signer = useMemo(() => {
    if (!user || !document.agreements) return undefined
    return document.agreements.find((ag) => ag.user_id === user.id && ag.is_signer && !ag.is_complete)
  }, [user])

  const isAllowed = signer && ['sign'].includes(document.status)


  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleSign = (signature: any) => {
    sign({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
        signature,
      },
    })
      .unwrap()
      .then((res) => {
        onSign && onSign()
      })
      .catch(toastError)
      .finally(() => setOpen(false))
  }

  if (!isAllowed) return null

  return (
    <>
      <LoadingButton variant={'outlined'} color={'success'} onClick={handleClickOpen} size={size}>
        Подписать
      </LoadingButton>
      <SignDialog document={document} onSign={handleSign} open={open} setOpen={setOpen} />
    </>
  )
}

export { SignButton }
