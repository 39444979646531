import { TLocation } from './location'

export type TDocumentStatus = 'draft' | 'rework' | 'sign' | 'success' | 'old'

export const statusLabel: Record<TDocumentStatus, string> = {
  draft: 'в работе',
  rework: 'на доработке',
  sign: 'подписание',
  success: 'подписан',
  old: 'отменен'
}

export const documentType = {
  GeneralWorkJournal: "GeneralWorkJournal",
  Representative: 'representative',
  GWJ1Item: 'GWJ1Item',
  GWJ2Item: 'GWJ2Item',
  GWJ3Item: 'GWJ3Item',
  GWJ4ControlEvent: 'GWJ4ControlEvent',
  GWJ4Item: 'GWJ4Item',
  GWJ4DefectInfo: 'GWJ4DefectInfo',
  GWJ6Item: 'GWJ6Item',
  GWJ7Item: 'GWJ7Item',
  GWJ7Defect: 'GWJ7Defect',
  IncomingMaterialControlJournal: "IncomingMaterialControlJournal",
  IMCRepresentative: 'IMCRepresentative',
  IMCItem: 'IMCItem',
  SupervisoryAuthorityRegistry: 'SupervisoryAuthorityRegistry',
  TitleChangesListItem: 'TitleChangesListItem',
  ActOSR: 'ActOSR'
} as const

export const baseDocumentType = {
  generalWorkJournal: {name: 'GeneralWorkJournal', description: 'Сводная информация, лист изменений, 1, 2, 6 разделы'},
  participants: {name: 'Participants', description: 'Представители застройщика, тех. заказчика'},
  buildingParticipants: {name: 'BuildingParticipants', description: 'Представители ЛОС'},
  workInfo: {name: 'WorkInfo', description: '3 раздел'},
  sk5111: {name: 'sk5111', description: '4, 5 разделы'},
  gsn35: {name: 'gsn35', description: '7 раздел'},
  buildingInfo: {name: 'BuildingInfo', description: 'Регистрационная надпись ОГСН, Инспектор ОГСН'},
  incomingMaterialControlJournal: {name: 'IncomingMaterialControlJournal', description: 'Титульный лист (общая информация)'},
  materialsSelection: {name: 'MaterialsSelection', description: 'Список организаций и их представителей'},
  incomingMaterialsControlInfo: {name: 'IncomingMaterialsControlInfo', description: 'Сведения о входном контроле материалов и изделий (записи)'},
} as const

export type TBaseDocumentType = keyof typeof baseDocumentType

type Keys = keyof typeof documentType
export type TDocumentType = (typeof documentType)[Keys]

export interface IDocumentAgreement {
  id: string
  employee_id: string
  user_id: string
  is_signer: boolean
  is_complete: boolean
  is_agree: boolean|null
  comment: string|null
  signed_time: string
}

export interface IDocCompany {
  id: string
  company_id: string
  type: string
}

export interface IDocEmployee {
  id: string
  employee_id: string
  type: string
}

export interface IDocExtDocument {
  id: string
  external_document_id: string
  type: string
  meta: any
}
export interface IDocPrdDocument {
  id: string
  prd_id: string
  type: string
  lists: null | number[]
  meta: any
}

export interface IDocOver {
  id: string
  over_id: string
  type: string
  doc_type: TDocumentType
}

export interface IDocument {
  id: string
  building_id: string
  parent_id: string
  prev_id: null | string
  owner_id: string
  type: TDocumentType
  version: 1
  status: TDocumentStatus
  date: string
  number: null | string
  created_at: string
  data: null | any
  companies: Record<string, IDocCompany[]>
  employees: Record<string, IDocEmployee[]>
  external_documents: Record<string, IDocExtDocument[]>
  prd_documents: Record<'pd' | 'rd', IDocPrdDocument[]>
  over: Record<string, IDocOver[]>
  works: string[]
  location?: TLocation
  xml: null | string
  agreements: IDocumentAgreement[]
  is_build: boolean
  is_signed: boolean
}

export interface ISar extends IDocument {
  signer?: IDocEmployee
}
