import { IDocument } from '../../../../types/document'
import { TBuilding, TEmployee } from '../../../../types/building'
import { Alert, Card, CardContent, CardHeader, Grid } from '@mui/material'
import { formatDate } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import * as React from 'react'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { SignDocument } from '../../../document/SignDocument'
import Box from '@mui/material/Box'
import { useGetWorksQuery } from '../../../../store/works'
import { DataRow } from '../../../common/DataRow'
import { locationString } from '../../../../util/location'
import { EditRecord4DefectDialog } from './EditRecord4DefectDialog'
import { EditRecord4Dialog } from './EditRecord4Dialog'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { useMemo } from 'react'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    (item.employees &&
      employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
        .full_name) ||
    ''
  )
}

type Props = {
  building: TBuilding
  item: IDocument
  defect: IDocument
  onChange: () => void
  onReady: () => void
  onSign: () => void
  onDefectChange: () => void
}

export const Record4ViewDefect = ({
  building,
  item,
  defect,
  onChange,
  onReady,
  onSign,
  onDefectChange,
}: Props) => {
  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: works } = useGetWorksQuery(building.id)

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isManage = useMemo(() => {
    if (!['draft', 'rework'].includes(item.status)) return false
    return (
      (user && authManager.vote('Document.GWJ4Item', 'manage', item.owner_id === user.id)) || false
    )
  }, [item, user])
  const isDefectManage = useMemo(() => {
    if (!['draft', 'rework'].includes(defect.status)) return false
    return (
      (user && authManager.vote('Document.GWJ4Item', 'manage', item.owner_id === user.id)) || false
    )
  }, [item, defect, user])

  return (
    <>
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title={'Недостаток'}
              subheader={'выявленное нарушение'}
              action={<StatusChip status={defect.status} />}
            />
            <CardContent>
              <DataRow label={'Дата внесения записи'} text={formatDate(defect.date)} />
              <DataRow label={'Номер недостатка'} text={defect.number} />
              <DataRow
                label={
                  'Список работ в отношении структурного элемента и результата работ в указанном месторасположении'
                }
                text={works && defect.works.map((workId) => works[workId].name).join(', ')}
              />
              {defect.location && (
                <DataRow label={'Местоположение'} text={locationString(defect.location)} />
              )}
              <DataRow label={'Описание недостатка'} text={defect?.data.description} />
              <DataRow
                label={'Наименование и обозначение структурного элемента'}
                text={defect?.data.structural_element}
              />
              <DataRow
                label={'Установленная (запланированная) дата устранения выявленного недостатка'}
                text={formatDate(defect.data.defect_fixing_plan_date)}
              />
              <DataRow
                label={'Сотрудник, проводивший контроль'}
                text={employees && signerName(employees, defect)}
              />
            </CardContent>
            {isDefectManage && (
              <CardContent>
                <EditRecord4DefectDialog defect={defect} onChange={onDefectChange} />
              </CardContent>
            )}
          </Card>
          <Box sx={{ mt: 2 }}>
            <SignDocument
              document={defect}
              onReady={onDefectChange}
              onChange={onDefectChange}
              description={'подписание данных о выявленном недостатке'}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title={'Устранение'}
              subheader={' '}
              action={<StatusChip status={item.status} />}
            />
            <CardContent>
              <DataRow
                label={'Фактическая дата устранения'}
                text={
                  item.data.defect_fixing_fact_date
                    ? formatDate(item.data.defect_fixing_fact_date)
                    : 'не задано'
                }
              />
              <DataRow
                label={'Сотрудник, проводивший контроль устранения недостатка'}
                text={employees && signerName(employees, item)}
              />
            </CardContent>
            {isManage && (
              <CardContent>
                <EditRecord4Dialog item={item} />
              </CardContent>
            )}
          </Card>
          <Box sx={{ mt: 2 }}>
            {defect.status === 'success' ? (
              <SignDocument
                document={item}
                onReady={onReady}
                onChange={onSign}
                description={'подписание данных об устранении недостатка'}
              />
            ) : (
              <Card>
                <CardContent>
                  <Alert severity={'info'}>
                    Подписание данных об устранении недостатка, будет доступно после подписания
                    данных о выявленном недостатке
                  </Alert>
                </CardContent>
              </Card>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
