import { IDocument } from '../../types/document'
import { Card, CardActions, CardContent, CardHeader, Divider, Skeleton, Stack, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { green } from '@mui/material/colors'
import React from 'react'
import { BuildButton } from './BuildButton'
import { SignDocumentList } from './SignDocumentList'
import { SignButton } from './SignButton'
import { DenyButton } from './DenyButton'
import { AgreeButton } from './AgreeButton'
import { SignDocumentActions } from './SignDocumentActions'

type Props = {
  document: IDocument
  onReady?: () => void
  onChange?: () => void
  description?: string
  isAgreement?: boolean
}

export const SignDocument = ({ document, description, onReady, onChange, isAgreement = false }: Props) => {


  return (
    <Card variant={'elevation'} elevation={0}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: green[500] }}>
            <VpnKeyIcon />
          </Avatar>
        }
        title="Подписание"
        subheader={isAgreement && document.status === 'rework'
          ? 'результат предыдущего согласования'
          : description}

        action={['sign'].includes(document.status) && <SignDocumentActions document={document} />}
      />
      {['rework', 'sign', 'success', 'old'].includes(document.status) &&
        <CardContent sx={{pt: 0, pb:0, pl: '25px'}}>
          <SignDocumentList document={document} />
        </CardContent>
      }
      {['sign'].includes(document.status) ? (
        <>
          <Divider />
          <CardActions>
            <Stack spacing={1} direction={'row'}>
              <SignButton document={document} onSign={onChange} />
              <AgreeButton document={document} onAgree={onChange} />
              <DenyButton document={document} onDeny={onChange} />
            </Stack>
          </CardActions>
        </>
      ) : (
        <>
        {['rework', 'draft'].includes(document.status) && (
          <>
            <Divider />
            <CardActions>
              <Stack spacing={1} direction={'row'}>
                <BuildButton
                  document={document}
                  onBuild={onReady}
                  text={isAgreement ? 'На согласование' : 'Сформировать'}
                  isConfirm={isAgreement}
                />
              </Stack>
            </CardActions>
          </>
        )}
        </>
      )}
    </Card>
  )
}
