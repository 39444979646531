import { TBuilding, TEmployee } from '../../../../types/building'
import { RightPanel, SkidPagination, useSkidPagination } from '../../../common'
import {
  Box,
  Button,
  CardHeader,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { formatDate } from '../../../../util/date'
import { toast } from 'react-toastify'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../../store/document'
import { documentType } from '../../../../types/document'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { Part6View } from './Part6View'
import { Part6Dialog } from './Part6Dialog'
import { toastError } from '../../../../util/toastError'
import { StatusChip } from '../../../common/StatusChip'

const signerName = (employees: TEmployee[], employee_id: string): string => {
  return employees.find((em) => em.id === employee_id)?.profile.name.full_name || ''
}

export const Part6List = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { pages, page, offset, limit, onPageChange, setTotal } = useSkidPagination(20)

  const { data, isLoading, isSuccess, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.GWJ6Item, parent_id: journalId },
  })

  const sorted = useMemo(() => {
    if (!data || data.items.length < 1) return []
    return Array.from(data.items).sort(
      (a, b) => new Date(b.date).getDate() - new Date(a.date).getDate()
    )
  }, [data])

  const { data: employees } = useGetEmployeesQuery(building.id)

  const [removeItem] = useRemoveDocumentMutation()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.GWJ6Item', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.GWJ6Item', 'manage', currentItem.owner_id === user.id) &&
        ['draft', 'rework'].includes(currentItem.status) ) ||
      false
    )
  }, [currentItem, user])

  if (isSuccess) {
    setTotal(data.metadata.total)
  }

  if (isLoading || data === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const handleRemove = (id: string | undefined, e: React.MouseEvent) => {
    e.stopPropagation()
    if (id) {
      removeItem({
        buildingId: building.id,
        body: {
          document_id: id,
          type: documentType.GWJ6Item,
        },
      })
        .unwrap()
        .then((res) => {
          refetch()
          setDrawerOpen(false)
          toast.success('Запись удалена')
        })
        .catch(toastError)
    }
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <CardHeader
            title={'Перечень исполнительной документации'}
            action={isCreate && <Part6Dialog buildingId={building.id} />}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Документ</TableCell>
                <TableCell>Подписанты</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sorted.slice(offset, offset + limit).map((item) => {
                return (
                  <TableRow
                    key={item.id}
                    hover
                    onClick={() => viewItem(item.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <Box>
                        <Typography variant={'body1'}>
                          {item.data.name} {item.data.number} от {formatDate(item.data.date)}
                        </Typography>
                      </Box>
                      <Typography variant={'caption'} color={'text.disabled'}>
                        {item.data.docSubject}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {employees &&
                        item.employees.signer &&
                        item.employees.signer.map((de) => (
                          <Box key={de.id}>
                            <Box>
                              <Typography variant={'body1'}>
                                {signerName(employees, de.employee_id)}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </TableCell>
                    <TableCell>
                      <StatusChip status={item.status} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {data.items && data.items.length > 0 && (
            <SkidPagination page={page} pages={pages} onChange={onPageChange} />
          )}
        </TableContainer>
      </Paper>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={(e) => handleRemove(currentItem?.id, e)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && <Part6View document={currentItem} onChange={refetch} isManage={isManage} />}
      </RightPanel>
    </>
  )
}
