import { IDocument } from '../../types/document'
import { MoreVertMenu } from '../menu'
import { ReworkButton } from './ReworkButton'
import { MenuItem } from '@mui/material'
import { ResetButton } from './ResetButton'
import React, { useState } from 'react'

type Props = {
  document: IDocument
}
export const SignDocumentActions = ({document}: Props) => {

  const [open, setOpen] = useState(false)

  return (
    <MoreVertMenu open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
      <ReworkButton document={document} renderButton={(onClick) => (
        <MenuItem onClick={onClick}>На доработку</MenuItem>
      )} />
      <ResetButton document={document} renderButton={(onClick) => (
        <MenuItem onClick={onClick}>Сбросить</MenuItem>
      )} />
    </MoreVertMenu>
  )

}
