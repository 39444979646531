import { IDocument } from '../../types/document'
import { useAgreeMutation } from '../../store/document'
import { useGetUserQuery } from '../../store/user'
import React, { useMemo, useState } from 'react'
import { toastError } from '../../util/toastError'
import { LoadingButton } from '@mui/lab'


type Props = {
  document: IDocument
  onAgree?: () => void
  size?: 'small' | 'medium'
}
const AgreeButton = ({ document, onAgree, size = 'medium' }: Props) => {

  const [isLoading, setIsLoading] = useState(false)

  const { data: user } = useGetUserQuery()
  const [agree] = useAgreeMutation()

  const signer = useMemo(() => {
    if (!user || !document.agreements) return undefined
    return document.agreements.find((ag) => ag.user_id === user.id && !ag.is_signer && !ag.is_complete)
  }, [user])

  const isAllowed = signer && ['sign'].includes(document.status)

  const handleAgree = () => {
    setIsLoading(true)
    agree({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
      },
    })
      .unwrap()
      .then((res) => {
        onAgree && onAgree()
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  if (!isAllowed) return null

  return (
    <LoadingButton variant={'outlined'} color={'success'} onClick={handleAgree} size={size} loading={isLoading}>
      Согласовать
    </LoadingButton>
  )
}

export { AgreeButton }
