import { TBuilding } from '../../../../types/building'
import { useGetGroupsQuery, useGetWorksQuery } from '../../../../store/works'
import { useGetEmployeesQuery } from '../../../../store/employees'
import * as React from 'react'
import { Box, Divider, Stack } from '@mui/material'
import { SignDocument } from '../../../document/SignDocument'
import { IDocument } from '../../../../types/document'
import { Item3ViewData } from './Item3ViewData'
import { Item3ViewMaterials } from './Item3ViewMaterials'
import { Item3ViewPrd } from './Item3ViewPrd'
import { Item3ViewExtDocs } from './Item3ViewExtDocs'
import { useGetUserQuery } from '../../../../store/user'

type Props = {
  building: TBuilding
  item: IDocument
  onReady: () => void
  onSign: () => void
  onChange: () => void
  isManage: boolean
}

export const Item3View = ({ building, item, onSign, onReady, onChange, isManage }: Props) => {
  const { data: works } = useGetWorksQuery(building.id)
  const { data: groups } = useGetGroupsQuery(building.id)
  const { data: employees } = useGetEmployeesQuery(building.id)

  return (
    <Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Box>
          {works && groups && employees && (
            <Item3ViewData
              item={item}
              works={works}
              groups={groups}
              employees={employees}
              isManage={isManage}
            />
          )}
          {works && <Item3ViewMaterials item={item} works={works} isManage={isManage} />}
        </Box>
        <Box>
          <Item3ViewPrd item={item} type={'pd'} onChange={onChange} isManage={isManage} />
          <Item3ViewPrd item={item} type={'rd'} onChange={onChange} isManage={isManage} />
          <Item3ViewExtDocs item={item} type={'isp'} onChange={onChange} isManage={isManage} />
          <Item3ViewExtDocs item={item} type={'result'} onChange={onChange} isManage={isManage} />
        </Box>
      </Stack>
      <Box>
        <SignDocument document={item} onReady={onReady} onChange={onSign}  />
      </Box>
    </Box>
  )
}
