import { IDocument } from '../../types/document'
import { useGetUserQuery } from '../../store/user'
import { Skeleton, Stack } from '@mui/material'
import React, { useMemo } from 'react'
import { useAuthorization } from '../../auth'
import { SignButton } from './SignButton'
import { LightChip } from '../common/LightChip'
import { BuildButton } from './BuildButton'
import { ResetButton } from './ResetButton'

type Props = {
  document: IDocument
  onReady?: () => void
  onSign?: () => void
  size?: 'small' | 'medium'
}

export const SignDocumentButton = ({
  document,
  onReady,
  onSign,
  size = 'medium',
}: Props) => {
  const { data: user, isSuccess: isUserSuccess, isLoading: isUserLoading } = useGetUserQuery()

  const authManager = useAuthorization()

  const isOwner = useMemo(() => {
    return user ? authManager.vote('Document', 'manage', user.id === document.owner_id) : false
  }, [document, user, authManager])

  if (isUserLoading || !isUserSuccess || user === undefined) {
    return <Skeleton height={100} />
  }

  switch (document.status) {
    case 'draft':
    case 'rework':
      return isOwner ? (
        <BuildButton document={document} onBuild={onReady} size={size} />
      ) : null
    case 'sign':
      return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <SignButton document={document} onSign={onSign} />
          {isOwner && (
            <ResetButton document={document} size={size} onReset={onReady} />
          )}
        </Stack>
      )
    case 'success':
      return (
        <>
          <LightChip label={'подписан'} color={'success'} size={'small'} />
        </>
      )
    default:
      return null
  }
}
