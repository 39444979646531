import { IDocEmployee, IDocument } from '../../../../types/document'
import { Box, Card, CardContent, CardHeader, MenuItem, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { DataRow } from '../../../common/DataRow'
import { TCompany, TEmployee } from '../../../../types/building'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { formatDate } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import EditItem1Dialog from './EditItem1Dialog'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { SignDocument } from '../../../document/SignDocument'

const employeeName = (docEmployee: IDocEmployee, employees: TEmployee[]): string => {
  return employees.find((em) => em.id === docEmployee.employee_id)?.profile.name.full_name || ''
}

const companyName = (
  docEmployee: IDocEmployee,
  employees: TEmployee[],
  companies: TCompany[]
): string => {
  const employee = employees.find((em) => em.id === docEmployee.employee_id)
  if (!employee || companies === undefined) return ''

  return companies.find((c) => c.id === employee.company_id)?.name || ''
}

type Props = {
  document: IDocument
  onSign: () => void
}
const Part1View = ({ document, onSign }: Props) => {
  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()

  const isManage = useMemo(() => {
    return (
      (user &&
        ['draft', 'rework'].includes(document.status) &&
        authManager.vote('Document.GWJ1Item', 'manage', document.owner_id === user.id)) ||
      false
    )
  }, [user, document])

  const { data: employees } = useGetEmployeesQuery(document.building_id)
  const { data: companies } = useGetCompaniesQuery(document.building_id)

  return (
    <Stack direction={'column'} spacing={2}>
      <Card>
        <CardHeader avatar={<StatusChip status={document.status} />} />
        <CardContent>
          <DataRow label={'Сотрудник'}>
            <Box>
              <Typography variant={'body1'}>
                {employees && employeeName(document.employees?.itp[0], employees)}
              </Typography>
            </Box>
            <Box>
              <Typography variant={'caption'} color={'grey'}>
                {companies &&
                  employees &&
                  companyName(document.employees?.itp[0], employees, companies)}
              </Typography>
            </Box>
          </DataRow>
          <DataRow
            label={'Вид работ'}
            text={
              Array.isArray(document.data.work_type)
                ? document.data.work_type.join(', ')
                : document.data.work_type
            }
          />
          <DataRow
            label={'Дата начала/окончания работ'}
            text={`${formatDate(document.data.date_begin)} - ${formatDate(document.data.date_end)}`}
          />
          <DataRow
            label={'Представитель лица, осуществляющего строительство, внёсший запись'}
            text={employees && employeeName(document.employees?.signer[0], employees)}
          />
        </CardContent>
        {isManage && (
          <CardContent>
            <EditItem1Dialog document={document} />
          </CardContent>
        )}
      </Card>
      <SignDocument document={document} onChange={onSign} onReady={onSign} />
    </Stack>
  )
}

export { Part1View }
